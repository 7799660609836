import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import SocialProfile from "components/SocialProfile/SocialProfile"
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoInstagram,
  IoLogoLinkedin,
} from "react-icons/io"
import {
  AboutWrapper,
  AboutImage,
  AboutPageTitle,
  AboutDetails,
  SocialProfiles,
} from "./style"

const SocialLinks = [
  // {
  //   icon: <IoLogoFacebook />,
  //   url: "https://www.facebook.com/redqinc/",
  //   tooltip: "Facebook",
  // },
  // {
  //   icon: <IoLogoInstagram />,
  //   url: "https://www.instagram.com/redqinc/",
  //   tooltip: "Instagram",
  // },
  // {
  //   icon: <IoLogoTwitter />,
  //   url: "https://twitter.com/redqinc",
  //   tooltip: "Twitter",
  // },
  {
    icon: <IoLogoLinkedin />,
    url: "https://www.linkedin.com/in/patryk-dziarnecki-1354b261/",
    tooltip: "Linked In",
  },
]

interface AboutProps {}

const About: React.FunctionComponent<AboutProps> = props => {
  const Data = useStaticQuery(graphql`
    query {
      avatar: file(absolutePath: { regex: "/about.jpg/" }) {
        childImageSharp {
          fluid(maxWidth: 1770, quality: 90) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      site {
        siteMetadata {
          author
          about
        }
      }
    }
  `)

  return (
    <AboutWrapper>
      {/* <AboutPageTitle>
        <h2>About Me</h2>
        <p>
          StoryHub is a beautiful Gatsby Blog theme designed to showcase your
          work in style. Perfect for designers, artists, photographers and
          developers to use for their portfolio website.
        </p>
      </AboutPageTitle> */}

      {/* <AboutImage>
        <Image fluid={Data.avatar.childImageSharp.fluid} alt="author" />
      </AboutImage> */}

      <AboutDetails>
        <h1>About me</h1>
        <p>
          I'm a software developer that wants to learn. 
          My goal is to always do things better than yesterday.
        </p>
        <p>
          I like to explore paradigms and technologies.
          Currently, my technologies of choice are ASP.NET Core on the bachend,
          and React on the frontend.
        </p>

        <SocialProfiles>
          <SocialProfile items={SocialLinks} />
        </SocialProfiles>
      </AboutDetails>
    </AboutWrapper>
  )
}

export default About
